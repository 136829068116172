import React from "react"

import Layout from '../components/layout'
import SEO from '../components/seo'

const Success = () => {
  return (
    <Layout>
      <SEO />
      <main>
        <div className="l-contact l-wrap">
          <h2 className="m-headign">お問い合わせありがとうございました</h2>
          <div className="l-contact__success">
            担当者から返信させていただきます。<br />

            今しばらくお待ちいただきますようよろしくお願いいたします。
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Success